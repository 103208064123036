import React from "react";
import {
  Box,
  Grid as Grid2,
  Typography,
  Card,
  CardContent,
  Avatar,
} from "@mui/material";
import DesarrolloSoftware from "../../../assets/desarrollodesoftware.png";
import Klaviyo from "../../../assets/klaviyo.png";
import Mailship from "../../../assets/mailship.png";
import Sendgrid from "../../../assets/sendgrid.png";
import Rd from "../../../assets/rd.png";
import M from "../../../assets/M.png";
import Ult from "../../../assets/ult.png";
import X from "../../../assets/X.png";
import Tiktok from "../../../assets/tiktok.png";
import Facebook from "../../../assets/facebook.png";
import Insta from "../../../assets/insta.png";
import DiseñoYdesarrollo from "../../../assets/DiseñoYdesarrollo.png";
import MarketingContenidos from "../../../assets/marketingContenidos.png";
const styles = {
  card: {
    width: "100%",
    maxWidth: "380px",
    color: "#fff",
    borderRadius: "10px",
    transition: "transform 0.3s, box-shadow 0.3s, background 0.3s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "450px",
    padding: "20px",
    marginBottom: "30px",
    boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.2)",
    background: "transparent",
    position: "relative", // Required for positioning pseudo-elements
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
      background: "linear-gradient(150deg, #1e0625 10%, #2b0932 60%)",

    },
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      width: "300px", // Aumenta el tamaño
      height: "300px", // Aumenta el tamaño
      borderRadius: "50%",
      background:
        "radial-gradient(circle, rgba(115, 17, 187, 0.7), transparent 65%)", // Más difuminado
      transition: "background 0.3s",
    },
    "&::before": {
      top: "-150px", // Ajusta la posición si es necesario
      left: "-150px", // Ajusta la posición si es necesario
    },
    "&::after": {
      bottom: "-150px", // Ajusta la posición si es necesario
      right: "-150px", // Ajusta la posición si es necesario
    },
    "&:hover::before, &:hover::after": {
      background:
        "transparent", // Ajusta el difuminado en hover
    },
  },

  cardSmall: {
    width: "100%",
    color: "#fff",
    padding: "10px",
    borderRadius: "10px",
    transition: "transform 0.3s, box-shadow 0.3s, background 0.3s",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: { xs: "100%", sm: "auto", md: "200px" },
    boxShadow: "0px 15px 25px rgba(0, 0, 0, 0.2)",
    background: "transparent",
    position: "relative", // Required for positioning pseudo-elements
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
      background: "linear-gradient(150deg, #1e0625 10%, #2b0932 60%)",
    },
    "&::before, &::after": {
      content: '""',
      position: "absolute",
      width: "300px", // Aumenta el tamaño
      height: "300px", // Aumenta el tamaño
      borderRadius: "50%",
      background:
        "radial-gradient(circle, rgba(115, 17, 187, 0.7), transparent 65%)", // Más difuminado
      transition: "background 0.3s",
    },
    "&::before": {
      top: "-150px", // Ajusta la posición si es necesario
      left: "-150px", // Ajusta la posición si es necesario
    },
    "&::after": {
      bottom: "-150px", // Ajusta la posición si es necesario
      right: "-150px", // Ajusta la posición si es necesario
    },
    "&:hover::before, &:hover::after": {
      background:
        "transparent", // Ajusta el difuminado en hover
    },
  },

  cardImage: {
    maxWidth: "80%",
    height: "auto",
    marginBottom: "20px",
    display: "flex",
    zIndex: 100,
  },
  container: {
    display: "flex",
    justifyContent: "center", // Centrar horizontalmente
    alignItems: "center", // Centrar verticalmente
  },
  avatarContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "center",
    width: "100%",
  },
  avatar: {
    width: 50,
    height: 50,
    backgroundColor: "#fff",
    "& img": {
      width: "80%",
      height: "80%",
      objectFit: "contain",
    },
  },
  cardContent: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "60%",
    zIndex: 100,
  },
};

const SectionService = () => {
  return (
    <Box
      sx={{
        padding: "10px 10px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          textAlign: "center",
          marginBottom: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            color: "#B51AD8",
            marginBottom: "10px",
            fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
          }}
        >
          LO QUE PODEMOS OFRECER
        </Typography>
        <Typography
          variant="h4"
          sx={{
            fontSize: {
              xs: "30px",
              sm: "2.5rem",
              md: "3rem",
              lg: "3.25rem",
            },
            width: { xs: "90%", sm: "60%", md: "60%", lg: "60%" },
            textAlign: "center",
            color: "#fff",
            fontWeight: "bold",
          }}
        >
          SOLUCIONES PARA MARCAS Y EMPRESAS DE IMPACTO GLOBAL
        </Typography>
      </Box>

      <Box sx={{ maxWidth: "100vw", padding: {xs:"30px", sm: "20px 0px"} }}>
        <Grid2
          container
          spacing={5}
          justifyContent="center"
          alignItems="center"
        >
          {/* Card 1 */}
          <Grid2 item xs={12} md={4} display="flex" justifyContent="center">
            <Card sx={styles.card}>
              <CardContent>
                <Box sx={styles.container}>
                  <img
                    src={DesarrolloSoftware}
                    alt="Desarrollo de software"
                    style={styles.cardImage}
                  />
                </Box>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  DESARROLLO DE SOFTWARE Y APPS A LA MEDIDA
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Adaptados a las necesidades específicas de tu negocio para
                  optimizar procesos y mejorar la eficiencia.
                </Typography>
              </CardContent>
            </Card>
          </Grid2>

          {/* Card 2 */}
          <Grid2 item xs={12} md={4} display="flex" justifyContent="center">
            <Card sx={styles.card}>
              <CardContent>
                <Box sx={styles.container}>
                  <img
                    src={DiseñoYdesarrollo}
                    alt="Diseño y Desarrollo Web"
                    style={styles.cardImage}
                  />
                </Box>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  DISEÑO Y DESARROLLO WEB UX - UI
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Creamos sitios atractivos, funcionales y optimizados para las
                  necesidades de tu marca.
                </Typography>
              </CardContent>
            </Card>
          </Grid2>

          {/* Card 3 */}
          <Grid2 item xs={12} md={4} display="flex" justifyContent="center">
            <Card sx={styles.card}>
              <CardContent>
                <Box sx={styles.container}>
                  <img
                    src={MarketingContenidos}
                    alt="Marketing Digital"
                    style={styles.cardImage}
                  />
                </Box>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  MARKETING DIGITAL Y DE CONTENIDO
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Potencia tu marca con estrategias personalizadas en SEO, redes
                  sociales, publicidad online y contenido relevante.
                </Typography>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>

        <Grid2
          container
          marginTop={2}
          spacing={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding={5}
          sx={{ gap: {xs: 5, sm: 18}, maxWidth: "100vw", padding: {xs:"0px", sm: "0"} }}
        >
          {/* Card 4 */}
          <Grid2
            item
            xs={12}
            sm={5}
            sx={{ marginTop: { xs: "20px", sm: "0px" } }}
          >
            <Card sx={styles.cardSmall}>
              <CardContent sx={styles.cardContent}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  EMAIL MARKETING
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Estrategias de email marketing para conectar con tu audiencia,
                  aumentar el engagement y convertir suscriptores en clientes
                  fieles.
                </Typography>
              </CardContent>
              <CardContent sx={{ flex: 1 }}>
                <Box sx={styles.avatarContainer}>
                  <Avatar sx={styles.avatar}>
                    <img src={Klaviyo} alt="Klaviyo" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={Mailship} alt="Mailship" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={Sendgrid} alt="Sendgrid" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={Rd} alt="Rd" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={M} alt="M" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={Ult} alt="M" />
                  </Avatar>
                </Box>
              </CardContent>
            </Card>
          </Grid2>

          {/* Card 5 */}
          <Grid2
            item
            xs={12}
            sm={5}
            sx={{ marginTop: { xs: "10px", sm: "0px" } }}
          >
            <Card sx={styles.cardSmall}>
              <CardContent sx={styles.cardContent}>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  SOCIAL MEDIA MARKETING
                </Typography>
                <Typography variant="body2" sx={{ marginTop: 2 }}>
                  Estrategias para impulsar tu marca en redes sociales,
                  conectando con tu audiencia y aumentando tu presencia en
                  línea.
                </Typography>
              </CardContent>
              <CardContent sx={{ flex: 1 }}>
                <Box sx={styles.avatarContainer}>
                  <Avatar sx={styles.avatar}>
                    <img src={Tiktok} alt="Tiktok" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={Facebook} alt="Facebook" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={Insta} alt="Insta" />
                  </Avatar>
                  <Avatar sx={styles.avatar}>
                    <img src={X} alt="X" />
                  </Avatar>
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  );
};

export default SectionService;
