import React, { useState } from "react";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AboutUsSection = () => {
  // Estado para el acordeón abierto
  const [expanded, setExpanded] = useState("panel1");

  // Manejar el cambio de acordeón
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Determina el orden de los acordeones
  const getOrder = () => {
    if (expanded === "panel2") {
      return ["panel2", "panel3", "panel1"];
    } else if (expanded === "panel3") {
      return ["panel3", "panel1", "panel2"];
    }
    return ["panel1", "panel2", "panel3"];
  };

  const [first, second, third] = getOrder();

  return (
    <Grid
      sx={{
        padding: { xs: "0px 20px", lg: "0px 40px" },
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        maxWidth: "100%",
      }}
    >
      <Grid
        container
        spacing={4}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          width: "98%",
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {/* Columna de Descripción */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: { xs: 3, md: 0 }, padding: 0, width: "98%" }}>
            <Typography variant="h6" sx={{ mb: 2, color: "#B51AD8", fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" }}}>
              QUIENES SOMOS
            </Typography>

            <Typography
              variant="h3"
              sx={{
                fontWeight: "bold",
                mb: 3,
                color: "#ffffff",
                fontSize: {
                  xs: "30px",
                  sm: "2.5rem",
                  md: "3rem",
                  lg: "3.25rem",
                },
                textAlign: { xs: "left", md: "left" },
              }}
            >
              ACERCA DE NUESTRA EMPRESA
            </Typography>

            <Typography
              variant="body1"
              sx={{
                mb: 3,
                color: "#ffffff",
                fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" },
              }}
            >
              Somos una fuerza motriz detrás de la innovación transformadora y
              el cambio social positivo. Reconocemos que el futuro pertenece a
              aquellos que pueden adaptarse rápidamente a las demandas del
              mercado y abrazar la innovación disruptiva. Nuestro compromiso con
              el impacto social refleja nuestra creencia en que las empresas
              tienen la responsabilidad de contribuir al bienestar de la
              sociedad en su conjunto. Con un enfoque en la agilidad, la
              innovación y el impacto social, HitPoly se posiciona como líder en
              la creación de un futuro tecnológico más brillante y equitativo
              para todos.
            </Typography>
          </Box>
        </Grid>

        {/* Columna de Acordeones */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            backgroundColor: "transparent",
          }}
        >
          <Box sx={{ padding: 0, width: "98%", marginLeft: { xs: 0, md: 2 } }}>
            <Accordion
              expanded={expanded === first}
              onChange={handleChange(first)}
              sx={{ bgcolor: "transparent", color: "#ffffff" }}
            >
              <AccordionSummary
                sx={{
                  background:
                    "linear-gradient(180deg, #2a222f 0%, #1e1720 100%)",
                  borderRadius: "17px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
                }}
                expandIcon={
                  <AddIcon
                    sx={{
                      color: "white",
                      backgroundColor: "#B51AD8",
                      borderRadius: "5px",
                    }}
                  />
                }
              >
                <Typography>
                  {first === "panel1"
                    ? "NUESTRA MISIÓN"
                    : first === "panel2"
                    ? "NUESTRA VISIÓN"
                    : "NUESTROS VALORES"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background:
                    "linear-gradient(180deg, #2a222f 0%, #1e1720 100%)",
                  borderRadius: "10px",
                  marginTop: "-20px",
                  border: "1px solid rgba(255, 255, 255, 0.36)",
                  padding: "50px 20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" },
                  }}
                >
                  {first === "panel1" &&
                    "Nuestra misión es liberar el potencial humano a través de la tecnología, elevando los estándares de la innovación y la responsabilidad. Como guardianes del cambio, nos esforzamos por construir una marca que no solo lidere, sino que también inspire y empodere. Estamos tejiendo una re de posibilidades donde la tecnología no solo transforma, sino que también une a las personas y promueve un futuro más brillante y equitativo para todos."}
                  {first === "panel2" &&
                    "Nuestra visión es clara: ser pioneros en la creación de un futuro donde cada solución tecnológica, no solo impulse el crecimiento empresarial, sino que también inspire el progreso humano. Estamos construyendo más que tecnología; estamos forjando un legado de impacto y excelencia."}
                  {first === "panel3" &&
                    "Nos dedicamos a impulsar el cambio y el progreso mediante la innovación, desafiando límites y explorando nuevas fronteras tecnológicas. Nuestra responsabilidad social nos lleva a ser agentes del cambio positivo, promoviendo la equidad, la inclusión y la sostenibilidad. Buscamos la excelencia en todos nuestros esfuerzos, superando expectativas y manteniendo altos estándares de calidad en nuestros productos y servicios. Valoramos la colaboración y el trabajo en equipo, reconociendo que el éxito se construye a través de relaciones sólidas, comunicación abierta y apoyo mutuo."}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === second}
              onChange={handleChange(second)}
              sx={{ bgcolor: "transparent", color: "#ffffff", mt: 2 }}
            >
              <AccordionSummary
                sx={{
                  background:
                    "linear-gradient(180deg, #2a222f 0%, #1e1720 100%)",
                  borderRadius: "17px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
                }}
                expandIcon={
                  <AddIcon
                    sx={{
                      color: "white",
                      backgroundColor: "#B51AD8",
                      borderRadius: "5px",
                    }}
                  />
                }
              >
                <Typography>
                  {second === "panel1"
                    ? "NUESTRA MISIÓN"
                    : second === "panel2"
                    ? "NUESTRA VISIÓN"
                    : "NUESTROS VALORES"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background:
                    "linear-gradient(180deg, #2a222f 0%, #1e1720 100%)",
                  borderRadius: "10px",
                  marginTop: "-20px",
                  border: "1px solid rgba(255, 255, 255, 0.36)",
                  padding: "50px 20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" },
                  }}
                >
                  {first === "panel1" &&
                    "Nuestra misión es liberar el potencial humano a través de la tecnología, elevando los estándares de la innovación y la responsabilidad. Como guardianes del cambio, nos esforzamos por construir una marca que no solo lidere, sino que también inspire y empodere. Estamos tejiendo una re de posibilidades donde la tecnología no solo transforma, sino que también une a las personas y promueve un futuro más brillante y equitativo para todos."}
                  {first === "panel2" &&
                    "Nuestra visión es clara: ser pioneros en la creación de un futuro donde cada solución tecnológica, no solo impulse el crecimiento empresarial, sino que también inspire el progreso humano. Estamos construyendo más que tecnología; estamos forjando un legado de impacto y excelencia."}
                  {first === "panel3" &&
                    "Nos dedicamos a impulsar el cambio y el progreso mediante la innovación, desafiando límites y explorando nuevas fronteras tecnológicas. Nuestra responsabilidad social nos lleva a ser agentes del cambio positivo, promoviendo la equidad, la inclusión y la sostenibilidad. Buscamos la excelencia en todos nuestros esfuerzos, superando expectativas y manteniendo altos estándares de calidad en nuestros productos y servicios. Valoramos la colaboración y el trabajo en equipo, reconociendo que el éxito se construye a través de relaciones sólidas, comunicación abierta y apoyo mutuo."}
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === third}
              onChange={handleChange(third)}
              sx={{ bgcolor: "transparent", color: "#ffffff", mt: 2 }}
            >
              <AccordionSummary
                sx={{
                  background:
                    "linear-gradient(180deg, #2a222f 0%, #1e1720 100%)",
                  borderRadius: "17px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.5)",
                }}
                expandIcon={
                  <AddIcon
                    sx={{
                      color: "white",
                      backgroundColor: "#B51AD8",
                      borderRadius: "5px",
                    }}
                  />
                }
              >
                <Typography>
                  {third === "panel1"
                    ? "NUESTRA MISIÓN"
                    : third === "panel2"
                    ? "NUESTRA VISIÓN"
                    : "NUESTROS VALORES"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  background:
                    "linear-gradient(180deg, #2a222f 0%, #1e1720 100%)",
                  borderRadius: "10px",
                  marginTop: "-20px",
                  border: "1px solid rgba(255, 255, 255, 0.36)",
                  padding: "50px 20px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" },
                  }}
                >
                  {first === "panel1" &&
                    "Nuestra misión es liberar el potencial humano a través de la tecnología, elevando los estándares de la innovación y la responsabilidad. Como guardianes del cambio, nos esforzamos por construir una marca que no solo lidere, sino que también inspire y empodere. Estamos tejiendo una re de posibilidades donde la tecnología no solo transforma, sino que también une a las personas y promueve un futuro más brillante y equitativo para todos."}
                  {first === "panel2" &&
                    "Nuestra visión es clara: ser pioneros en la creación de un futuro donde cada solución tecnológica, no solo impulse el crecimiento empresarial, sino que también inspire el progreso humano. Estamos construyendo más que tecnología; estamos forjando un legado de impacto y excelencia."}
                  {first === "panel3" &&
                    "Nos dedicamos a impulsar el cambio y el progreso mediante la innovación, desafiando límites y explorando nuevas fronteras tecnológicas. Nuestra responsabilidad social nos lleva a ser agentes del cambio positivo, promoviendo la equidad, la inclusión y la sostenibilidad. Buscamos la excelencia en todos nuestros esfuerzos, superando expectativas y manteniendo altos estándares de calidad en nuestros productos y servicios. Valoramos la colaboración y el trabajo en equipo, reconociendo que el éxito se construye a través de relaciones sólidas, comunicación abierta y apoyo mutuo."}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AboutUsSection;
