import React, { useState } from "react";
import { AppBar, Toolbar, Box, Button, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import Logo from "../../assets/logohitpoly.png";
import { Link, useLocation } from "react-router-dom"; // Importar useLocation

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const location = useLocation(); // Obtener la ubicación actual

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleContactClick = () => {
    window.location.href = "/contact";
  };

  const drawerList = () => (
    <Box
      sx={{ maxWidth: 250, backgroundColor: "#000000", height: "100%",}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List sx={{ padding: "20px 0", }}>
        {navItems.map((item, index) => (
          <ListItem button key={index} sx={{ display: "block", textAlign: "center" }}>
            <Link to={navLinks[index]} style={navLinkStyle}>
              <ListItemText primary={item} sx={{ color: "#FFFFFF", fontSize: "14px" }} />
            </Link>
          </ListItem>
        ))}
        <ListItem button sx={{ display: "block", textAlign: "center", marginTop: 2 }}>
          <Button variant="contained" sx={contactButtonMobileStyle} onClick={handleContactClick}>
            CONTACTAR <ArrowOutwardIcon sx={{ fontSize: 14, marginLeft: 1 }} />
          </Button>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar position="static" color="transparent" elevation={0} sx={navbarStyle}>
      <Toolbar sx={toolbarStyle}>
        <Box sx={leftSectionStyle}>
          <img src={Logo} alt="Logo Hitpoly" style={{ height: "41px", marginTop: "10px" }} />
        </Box>
        <Box sx={centerSectionStyle}>
          {navItems.map((item, index) => {
            const isActive = location.pathname === navLinks[index]; // Verificar si el enlace está activo
            return (
              <Link to={navLinks[index]} key={index} style={navLinkStyle}>
                <Button
                  sx={{
                    ...navButtonStyle,
                    backgroundColor: isActive ? "#6F4CE0" : "transparent", // Fondo activo
                    "&:hover": {
                      backgroundColor: "#3E1F7E", // Fondo al hacer hover
                    },
                  }}
                >
                  {item}
                </Button>
              </Link>
            );
          })}
        </Box>
        <Box sx={rightSectionStyle}>
          <Button variant="contained" sx={contactButtonStyle} onClick={handleContactClick}>
            CONTACTAR <ArrowOutwardIcon sx={{ fontSize: 16, marginLeft: 1 }} />
          </Button>
        </Box>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ display: { xs: "block", md: "none" }, color: "#FFFFFF" }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
    </AppBar>
  );
};

const navItems = ["INICIO", "PORTAFOLIO", "SERVICIOS", "SOBRE NOSOTROS"];
const navLinks = ["/", "/portfolio", "/servicios", "/nosotros"];

const navbarStyle = {
  display: "flex",
  padding: "30px 20px",
  maxWidth: "100%",
};

const toolbarStyle = {
  width: "100%",
  maxWidth: "98%",
  margin: "0 auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};

const leftSectionStyle = {
  flex: 1,
  display: "flex",
  justifyContent: "flex-start",
};

const centerSectionStyle = {
  display: { xs: "none", md: "flex" },
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #ffffff47", // Borde de 1 píxel con color blanco
  borderRadius: "25px",
};

const rightSectionStyle = {
  flex: 1,
  display: { xs: "none", md: "flex" },
  justifyContent: "flex-end",
};

const navLinkStyle = {
  textDecoration: "none",
};

const navButtonStyle = {
  color: "#FFFFFF",
  padding: "8px 20px",
  borderRadius: "25px",
  fontSize: "12px",
  letterSpacing: "1px",
  transition: "background-color 0.3s ease",
};

const contactButtonStyle = {
  backgroundColor: "#6F4CE0",
  color: "#FFFFFF",
  borderRadius: "25px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "1px",
  transition: "background-color 0.3s ease", // Para una transición suave
  "&:hover": {
    backgroundColor: "#3E1F7E", // Cambia el color al hacer hover (por ejemplo, un tono más oscuro)
    color: "#FFFFFF", // O puedes cambiar el color del texto si lo prefieres
  },
};


const contactButtonMobileStyle = {
  backgroundColor: "#6F4CE0",
  color: "#FFFFFF",
  borderRadius: "25px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "bold",
  letterSpacing: "1px",
  width: "100%",
};

export default Navbar;
