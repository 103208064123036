import React, { useState } from "react";
import {
  Box,
  Grid2 as Grid,
  Typography,
  Button,
  OutlinedInput,
} from "@mui/material";
import Navbar from "../navbarHomeGral/navbarGral";
import Table from "../../assets/table.png";
import Izquierda from "../../assets/izquierda.png";
import Derecha from "../../assets/derecha.png";
import CardSection from "./components/CardSection";
import SectionService from "./components/SectionService";
import ServiceReviews from "./components/ServiceReviews";
import ComunityPublic from "./components/comunitypublic";
import StatisticsSection from "./components/StatisticsSection";
import Footer from "../footer/page";

const Home = () => {
  const [hoveredImage, setHoveredImage] = useState(null);

  // Función para manejar el evento de mouse enter en las imágenes
  const handleMouseEnter = (image) => {
    setHoveredImage(image);
  };

  // Función para manejar el evento de mouse leave en las imágenes
  const handleMouseLeave = () => {
    setHoveredImage(null);
  };

  return (
    <Box
      sx={{
        color: "#FFFFFF",
        fontFamily: "Poppins, sans-serif",
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Barra de navegación */}
      <Navbar />

      {/* Sección Hero */}
      <Box
        sx={{
          textAlign: "center",
          padding: { xs: "50px 25px", sm: "80px 40px", md: "70px 0px" }, // Padding responsivo
          maxWidth: { md:"95%"},
          margin: "0 auto",
          overflow: "hidden", // Ocultar parte del contenido que se desplaza fuera de la vista
        }}
      >
        {/* Subtítulo */}
        <Typography
          variant="h5"
          sx={{
            fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" }, // Tamaño de fuente responsivo
            color: "#B51AD8",
            marginBottom: { xs: "20px", sm: "10px" },
          }}
        >
          UN FUTURO CONECTADO
        </Typography>

        {/* Título Principal */}
        
        <Box sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>

        <Typography
          variant="h1"
          sx={{
            fontSize: {
              xs: "30px",
              sm: "2.5rem",
              md: "3rem",
              lg: "3.25rem",
            },
            color: "#FFFFFF",
            fontWeight: "bold",
            lineHeight: "1.2",
            marginBottom: "20px",
            width: "80%",
          }}
        >
          SOMOS UN ENTORNO ECONÓMICO DE CAPITALIZACIÓN EFECTIVA
        </Typography>
        </Box>

        {/* Descripción */}
        <Typography
          variant="body1"
          sx={{
            fontSize: { xs: "0.875rem", sm: "1rem", md: "0.9375rem" }, // Tamaño de fuente responsivo
            color: "#C0C0C0",
            margin: "20px auto",
            maxWidth: { xs: "90%", lg: "48%" },
          }}
        >
          Nos esforzamos cada día para seguir ayudando a que profesionales,
          emprendedores, empresarios e inversores cuenten con el único lugar
          centralizado de capitalización efectivo y seguro, donde puedan
          conseguir todo lo que necesitan para crecer a través de internet.
        </Typography>

        <Grid
          container
          justifyContent="center"
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: { xs: "40px", sm: "50px" },
          }}
        >
          <Grid
            item
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "25px",
              border: "1px solid #FFFFFF",
              padding: "2px",
              width: { xs: "90%", sm: "70%", md: "50%", lg: "38%" }, // Ancho responsivo
            }}
          >
            {/* Input para correo electrónico */}
            <OutlinedInput
              type="email"
              placeholder="E-mail"
              sx={{
                width: { xs: "auto", sm: "200px", md: "250px" },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
            />

            {/* Botón para agendar cita */}
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#6F4CE0",
                color: "#FFFFFF",
                width: 160,
                borderRadius: "25px",
                cursor: "pointer",
                transition: "background-color 0.3s",
                fontSize: { xs: "15px", sm: "15px" },
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#3E1F7E", // Color al hacer hover
                },
              }}
            >
              CONTACTAR
            </Button>
          </Grid>
        </Grid>

        {/* Sección de Imágenes Principales */}
        <Grid
          container
          sx={{
            position: "relative",
            gap: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
            width: "100%",
          }}
        >
          {/* Imagen Izquierda */}
          <Grid item xs={12} sm={4}>
            <Box
              onMouseEnter={() => handleMouseEnter("left")}
              onMouseLeave={handleMouseLeave}
            >
              <Box
                component="img"
                src={Izquierda}
                alt="Izquierda"
                sx={{
                  maxWidth: { xs: "100%", sm: "50%", md: "100%" },
                  height: { xs: "450px", sm: "280px", md: "440px" },
                  transition: "transform 0.3s ease",
                  borderRadius: "25px",
                  padding: "15px",
                  background: `
                    radial-gradient(circle at top left, #7311BB 1%, transparent 60%),
                    radial-gradient(circle at bottom right, #7311BB 10%, transparent 60%)
                  `,
                  backgroundSize: "50% 50%",
                  backgroundPosition: "top left, bottom right",
                  backgroundRepeat: "no-repeat",
                  transform:
                    hoveredImage === "left" ? "scale(1.05)" : "scale(1)",
                  marginTop: { xs: "100px", sm: "60px", md: "-250px" }, // Margen responsivo
                  marginLeft: { xs: "40px", sm: "320px", md: "-20px" }, // Margen responsivo
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Imagen Central */}
          <Grid item xs={12} sm={4}>
            <Box
              onMouseEnter={() => handleMouseEnter("center")}
              onMouseLeave={handleMouseLeave}
              sx={{
                display: { xs: "flex" },
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={Table}
                alt="Table"
                sx={{
                  maxWidth: "100%",
                  height: {
                    xs: "250px",
                    sm: "300px",
                    md: "340px",
                    lg: "380px",
                  }, // Altura responsiva
                  transition: "transform 0.3s ease",
                  transform:
                    hoveredImage === "center" ? "scale(1.0)" : "scale(1)",
                  marginTop: { xs: "20px", sm: "60px", md: "50px" }, // Margen responsivo
                  display: {
                    xs: "none",
                    sm: "block",
                    md: "block",
                    lg: "block",
                  },
                }}
              />
            </Box>
          </Grid>

          {/* Imagen Derecha */}
          <Grid item xs={12} sm={4}>
            <Box
              onMouseEnter={() => handleMouseEnter("right")}
              onMouseLeave={handleMouseLeave}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                src={Derecha}
                alt="Derecha"
                sx={{
                  position: "relative", // Margen responsivo
                  maxWidth: "100%",
                  height: { xs: "320px", sm: "auto", lg: "320px"},
                  transition: "transform 0.3s ease",
                  borderRadius: "25px",
                  padding: "15px",
                  background: `
                    radial-gradient(circle at top left, #7311BB 1%, transparent 60%),
                    radial-gradient(circle at bottom right, #7311BB 10%, transparent 60%)
                  `,
                  backgroundSize: "50% 50%",
                  backgroundPosition: "top left, bottom right",
                  backgroundRepeat: "no-repeat",
                  transform:
                    hoveredImage === "right" ? "scale(1.05)" : "scale(1)",
                  marginTop: { xs: "50px", sm: "60px", md: "-380px" }, // Margen responsivo
                  marginRight: { xs: "10px", sm: "320px", md: "-30px" }, // Margen responsivo
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Sección de Tarjetas */}
      <CardSection />

      {/* Sección de Cuidado y Servicio */}
      <SectionService />

       {/* Sección de Reseñas */}
       <ServiceReviews />

      {/* Sección de tarjeta de crecimiento */}
      <StatisticsSection />

      {/* Sección de Comunidad */}
      <ComunityPublic />

      {/* Pie de Página */}
        <Footer />

    </Box>
  );
};

export default Home;
