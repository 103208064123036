import React from "react";
import { Box, Typography, Grid2 as Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";

// Datos de las estadísticas con un valor máximo de 100
const stats = [
  { number: 28, label: "Clientes\nSatisfechos" },
  { number: 32, label: "Entregas\nCompletadas" },
  { number: 32, label: "Proyectos\nCompletados" },
  { number: 6, label: "Años De\nExperiencia" },
];

// Hook personalizado para animar el conteo con un valor máximo de 100
const useCountUp = (endValue, duration = 2000, maxValue = 100, inView) => {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    if (!inView) return; // No iniciar la animación si el elemento no está en vista

    let start = 0;
    const increment = Math.min(endValue, maxValue) / (duration / 10); // La cantidad a incrementar en cada intervalo
    const intervalId = setInterval(() => {
      start += increment;
      if (start >= Math.min(endValue, maxValue)) {
        start = Math.min(endValue, maxValue);
        clearInterval(intervalId); // Limpiar el intervalo cuando alcanzamos el valor final
      }
      setValue(Math.ceil(start)); // Redondear al valor entero más cercano
    }, 10); // Actualizar cada 10ms para una animación suave

    return () => clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonta
  }, [endValue, duration, maxValue, inView]);

  return value;
};

// Componente para cada elemento de estadística
const StatisticItem = ({ number, label }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Solo activar la animación una vez
    threshold: 0.1, // Porcentaje del componente visible antes de activar la animación
  });

  // Llamar al hook con la visibilidad del elemento
  const animatedNumber = useCountUp(number, 2000, 100, inView);

  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={3}
      sx={{
        textAlign: "center",
        position: "relative",
        "&:not(:last-child)": {
          borderRight: { xs: "none", md: "2px solid #33475b" },
        },
        paddingBottom: "2vh",
        paddingTop: "2vh",
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      }}
      ref={ref}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          
        }}
      >
        {/* Separar más los números */}
        <Typography
          variant="h4"
          sx={{
            color: "white", // Cambia el color a dorado
            fontWeight: "bold",
            fontSize: { xs: "2rem", sm: "3.5rem" }, // Aumenta el tamaño de la fuente
            marginBottom: "16px",
            marginLeft: {xs: "0px", sm: "26px"},
            marginRight: "26px",
            fontFamily: "Pipins, montserrat", // Aplica una fuente personalizada
          }}
        >
          {animatedNumber}
        </Typography>

        {/* Separar más el texto del número */}
        <Typography
          variant="subtitle1"
          sx={{
            color: "#B0B7C3",
            whiteSpace: "pre-line",
            marginLeft: {xs:"0px", sm:"6px"},
            marginRight: {xs:"0px", sm:"36px"},
            textAlign: "center",
            fontSize: { xs: ".8rem", sm: "1rem" }, // Tamaño más grande para el texto
          }}
        >
          {label}
        </Typography>
      </Box>
    </Grid>
  );
};

const StatisticsSection = () => {
  return (
    <Box
      sx={{
        padding: "1vh 0",
        display: "flex",
        marginTop: {xs: "30px", sm:"50px"},
        justifyContent: "center",
      }}
    >
      <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{
            boxSizing: "border-box",
            display: "flex",
            flexFlow: "wrap",
            flexDirection: {xs:"column", sm:"row"},
            width: "100%",
            maxWidth: "90vw",
            padding: "40px 60px",
            margin: "10px 30px",
            borderRadius: "25px",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        >
        {stats.map((stat, index) => (
          <StatisticItem key={index} number={stat.number} label={stat.label} />
        ))}
      </Grid>
    </Box>
  );
};

export default StatisticsSection;
