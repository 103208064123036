import React from "react";
import NavGral from "../navbarHomeGral/navbarGral";
import HomePage from "../nosotros/components/HomePage";
import AboutUsSection from "../nosotros/components/AboutUsSection";
import CeoCard from "../nosotros/components/CeoCard";
import StepGrid from "../nosotros/components/StepGrid";
import TeamMembers from "./components/TeamMembers";
import ServiceReviews from "./../home/components/ServiceReviews";
import FAQSection from "./../nosotros/components/FAQSection";
import Footer from "../footer/page";
import { Box, Grid2 } from "@mui/material";

const Nosotros = () => {
  return (
    <Box>
      <Grid2>
        <NavGral />
        <HomePage />
        <AboutUsSection />
        <CeoCard />
        <StepGrid />
        <TeamMembers />        
        <ServiceReviews />
        <FAQSection />
        <Footer />          
      </Grid2>
    </Box>
  );
};

export default Nosotros;
