import React, { useState } from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import Klaviyo from "../../../assets/klaviyo.png";
import Tigo from "../../../assets/tigo-logo.png";
import Jacmont from "../../../assets/jacmont-logo.png";
import Cietep from "../../../assets/cietep.png";
import Registration from "../../../assets/hk-registration.png";
import Futura from "../../../assets/futura-home.png";
import Mark from "../../../assets/mark-and-vetty.png";
import Oficitca from "../../../assets/oficitca.png";
import Laboratiriosfarma from "../../../assets/laboratorios-farma.png";
import Segurosamc from "../../../assets/seguros-amc.png";
import Acora from "../../../assets/acora.png";
import ArrowOutward from "@mui/icons-material/ArrowOutward"; // Importa el icono de la flecha

const servicesData = [
  // COMUMUNITY MANAGER
  {
    title: "Tigo Costa Rica",
    description:
      "En Tigo, construimos autopistas digitales que conectan a las personas, mejoran vidas y desarrollan nuestras comunidades. Impulsamos el valor de la conectividad digital para acercar personas, comunidades y empresas.",
    category: "Community Manager",
    image: Tigo,
  },
  {
    title: "Clinica Jacmont",
    description:
      "Somos pioneros en el uso de la Medicina Regenerativa y la Ozonoterapia Médica, brindando soluciones innovadoras para el tratamiento de diversas patologías. Nuestro enfoque se centra en mejorar la calidad de vida de los pacientes.",
    category: "Community Manager",
    image: Jacmont,
  },
  {
    title: "7P Marketing Corporation",
    description:
      "Creemos que los negocios son una vía para el auto-descubrimiento y para generar un impacto positivo. Nuestra misión es ayudar a las empresas a crecer con estrategias que conecten y aporten valor a sus audiencias.",
    category: "Community Manager",
    image: Cietep,
  },
  {
    title: "HK-Registration",
    description:
      "Brindamos servicios de asesoramiento jurídico y contable con el objetivo de asegurar la conformidad legal y optimizar la gestión empresarial. Guiamos a nuestros clientes a que impulsen su éxito y tranquilidad.",
    category: "Community Manager",
    image: Registration,
  },

  // DESARROLLO WEB & APP

  {
    title: "Futura Home",
    description:
      "En Futura Home, transformamos tu hogar en un espacio inteligente y eficiente. Ofrecemos soluciones innovadoras que automatizan funciones estratégicas para mejorar tu comodidad y optimizar tu estilo de vida.",
    category: "Desarrollo Web & App",
    image: Futura,
  },
  {
    title: "Mark and Vetty",
    description:
      "Potenciamos tu empresa con una solución digital integral. Ofrecemos servicios que incluyen branding, desarrollo de páginas web, gestión de redes sociales, producción audiovisual y creatividad comercial.",
    category: "Desarrollo Web & App",
    image: Mark,
  },
  {
    title: "Sistemas Modulares Oficit",
    description:
      "En Ofisitca, somos líderes en ofrecer soluciones integrales para tu oficina. Proporcionamos mobiliario de alta calidad, sistemas de almacenaje eficientes, equipos para carga pesada y un diseño de proyectos personalizado.",
    category: "Desarrollo Web & App",
    image: Oficitca,
  },
  {
    title: "Laboratorios farma",
    description:
      "Somos el primer laboratorio en Venezuela con certificación INVIMA y contamos con más de 80 años de experiencia. Ofrecemos productos de la más alta calidad para cuidar de tu salud con la mayor cercanía.",
    category: "Desarrollo Web & App",
    image: Laboratiriosfarma,
  },

  // PUBLICIDAD DIGITAL
  {
    title: "HK-Registration",
    description:
      "Brindamos servicios de asesoramiento jurídico y contable con el objetivo de asegurar la conformidad legal y optimizar la gestión empresarial. Guiamos a nuestros clientes a que impulsen su éxito y tranquilidad.",
    category: "Publicidad Digital",
    image: Registration,
  },
  {
    title: "7P Marketing Corporation",
    description:
      "Creemos que los negocios son una vía para el auto-descubrimiento y para generar un impacto positivo. Nuestra misión es ayudar a las empresas a crecer con estrategias que conecten y aporten valor a sus audiencias.",
    category: "Publicidad Digital",
    image: Cietep,
  },
  {
    title: "Seguros AMC",
    description:
      "Protege a tu familia con una póliza que te respalda en emergencias médicas. Con cobertura para accidentes leves y graves, asegúrate de tener la tranquilidad que necesitas. Te ayudamos de forma rápida y eficaz.",
    category: "Publicidad Digital",
    image: Segurosamc,
  },
  {
    title: "Casas inteligentes Acora",
    description:
      "Transformamos tu hogar con soluciones de domótica avanzadas para un estilo de vida moderno y eficiente. Automatizamos tu entorno para maximizar comodidad y seguridad.",
    category: "Publicidad Digital",
    image: Acora,
  },

  // DISEÑO UI/UX

  {
    title: "ARFASA ABOGADOS - PERÚ",
    description:
      "Logramos un ROI de 4.2% para esta firma legal en Lima, especializándonos en campañas de Google Ads, Facebook Ads, y gestión de redes sociales para diversas áreas del derecho.",
    category: "Diseño UI/UX",
    image: Klaviyo,
  },
  {
    title: "ARFASA ABOGADOS - PERÚ",
    description:
      "Logramos un ROI de 4.2% para esta firma legal en Lima, especializándonos en campañas de Google Ads, Facebook Ads, y gestión de redes sociales para diversas áreas del derecho.",
    category: "Diseño UI/UX",
    image: Klaviyo,
  },
  {
    title: "ARFASA ABOGADOS - PERÚ",
    description:
      "Logramos un ROI de 4.2% para esta firma legal en Lima, especializándonos en campañas de Google Ads, Facebook Ads, y gestión de redes sociales para diversas áreas del derecho.",
    category: "Diseño UI/UX",
    image: Klaviyo,
  },
  {
    title: "ARFASA ABOGADOS - PERÚ",
    description:
      "Logramos un ROI de 4.2% para esta firma legal en Lima, especializándonos en campañas de Google Ads, Facebook Ads, y gestión de redes sociales para diversas áreas del derecho.",
    category: "Diseño UI/UX",
    image: Klaviyo,
  },
];

const categories = [
  "Community Manager",
  "Desarrollo Web & App",
  "Publicidad Digital",
  "Diseño UI/UX",
];

const ServicesGrid = () => {
  const [selectedCategory, setSelectedCategory] = useState("Community Manager");

  const filteredServices = servicesData.filter(
    (service) => service.category === selectedCategory
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: { xs: "20px", sm: "10px 60px" },
        marginBottom: "50px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 4,
          flexWrap: "wrap",
        }}
      >
        {categories.map((category) => (
          <Button
            key={category}
            onClick={() => setSelectedCategory(category)}
            variant={selectedCategory === category ? "contained" : "outlined"}
            sx={{
              color: "#ffffff",
              borderColor: "#8a2be2",
              backgroundColor:
                selectedCategory === category ? "#373637" : "transparent",
              borderRadius: "10px",
              mx: 1,
              mb: 2,
              "&:hover": {
                backgroundColor: "#373637",
                color: "#fff",
              },
            }}
          >
            {category}
          </Button>
        ))}
      </Box>

      <Grid container spacing={2} sx={{
        justifyContent: "center",
        alignItems: "center",
        }}>
        {filteredServices.map((service, index) => (
          <Grid item xs={12} sm={6} md={6} lg={3} key={index}>
          <Card
            sx={{
              maxWidth: {xs: "90%", lg: 330},
              height: {xs: "auto",sm: "auto", md: "auto", lg: 500},
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between", // Asegura que los elementos se distribuyan en la tarjeta
              color: "#FFFFFF",
              background: `radial-gradient(circle at bottom right, #7311BB 10%, transparent 40%),
              radial-gradient(circle at top left, #7311BB 1%, transparent 0%)`,
              borderRadius: "20px",
              border: "1px solid #828080",
              margin: "0 auto",
            }}
          >
            {/* Contenedor padre de la imagen */}
            <Box
              sx={{
                position: "relative",
                height: 200, // Define la altura del contenedor padre
                background: `radial-gradient(circle at bottom left, #7311BB 0%, transparent 50%), 
                radial-gradient(circle at top right, #7311BB 1%, transparent 0%)`,
                borderRadius: "20px 20px 15px 15px", // Solo redondear las esquinas superiores
                borderBottom: "1px solid #828080", // Aplica el borde solo en la parte inferior
                display: "flex",
                justifyContent: "center",
                alignItems: "center", // Centrar la imagen
              }}
            >
              {/* Imagen encapsulada dentro de un contenedor con borde */}
              <CardMedia
                component="img"
                image={service.image}
                alt={service.title}
                sx={{
                  maxWidth: "70%", // Asegura que la imagen no exceda el contenedor
                  maxHeight: "60%", // Asegura que la imagen respete el contenedor
                  objectFit: "contain", // Asegura que la imagen mantenga su relación de aspecto
                  border: "1px solid #828080", // Aplica el borde de la imagen
                  borderRadius: "10px", // Opcional: redondea las esquinas de la imagen
                  padding: "20px",
                }}
              />
            </Box>
        
            <CardContent>
              <Typography variant="body1" sx={{ mb: 1, color: "#B51AD8" }}>
                {service.category}
              </Typography>
              <Typography
                variant="h6"
                sx={{ mb: 2, fontWeight: "bold", fontSize: "1.2rem" }}
              >
                {service.title}
              </Typography>
              <Typography variant="body2">{service.description}</Typography>
            </CardContent>
        
            {/* Botón "Saber Más" */}
            <Box sx={{ paddingBottom: "16px", ml: 2, mt: "auto" }}>
              <Button
                component="a"
                href="https://wa.me/51933961352"
                target="_blank"
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#6F4CE0", // Color de fondo
                  borderRadius: '20px',
                  width: { xs: "60%", sm: "90%", md: "60%" }, // Ajuste del ancho del botón
                  textTransform: "none", // Evita que el texto del botón esté en mayúsculas
                  boxShadow: "0 4px 14px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    backgroundColor: "#3E1F7E", // Cambia el color en hover
                  },
                }}
                endIcon={<ArrowOutward />} // Agrega el icono de flecha
              >
                Ver Resultados
              </Button>
            </Box>
          </Card>
        </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ServicesGrid;
