import React from "react";
import Navbar from "../navbarHomeGral/navbarGral";
import Footer from "../footer/page"
import { Box, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';


const Servicios =()=>{

    const StyledBox = styled(Box)`
    background: linear-gradient(
      to bottom,
      #000000,
      #241028, 
      #28122D  
    );
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    padding: 20px;
    color: #ffffff;
  `;
  
    return (
        <div>
            <Navbar/>
            <StyledBox>
            <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h6" align="center" sx={{ color: "#B51AD8" }}  gutterBottom>
                Nuestros Servicios
                </Typography>
                <Typography  variant="h5" align="center" sx={{ color: "#FFFFFF" }}  gutterBottom>
                Solucionamos problemas en marcas y empresas de alto impacto internacional
                </Typography>
                </Grid>  
                </Grid>
            </StyledBox>
            <Footer/>
        </div>
    )
}


export default Servicios;