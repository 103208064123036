import React from 'react';
import { Box, Typography, Grid, Card, CardMedia, CardContent, Button } from '@mui/material';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import Desarrollo from '../../../assets/Desarrollo.webp';
import SocialMedia from "../../../assets/socialmediamarketinG.webp";
import DigitalMarketing from "../../../assets/digitalmarketing.webp";

const services = [
  {
    title: 'Desarrollo de Software y Apps a la Medida (Actualizado).',
    description: 'Adaptamos nuestras soluciones de software y aplicaciones a las necesidades específicas de tu negocio, optimizando procesos y mejorando resultados.',
    image: Desarrollo,
  },
  {
    title: 'Inbound Marketing (Estrategias enfocadas en el consumidor).',
    description: 'Atrae a clientes potenciales de manera natural y orgánica con estrategias de Inbound Marketing diseñadas para tu negocio. Crecimiento a largo plazo.',
    image: SocialMedia,
  },
  {
    title: 'Campañas Publicitarias (Un gran alcance omnicanal).',
    description: 'Maximiza tu alcance y genera resultados medibles con campañas publicitarias diseñadas para captar la atención de tu audiencia ideal.',
    image: DigitalMarketing,
  },
];

const ComunityPublic = () => {
  return (
    <Box
      sx={{
        padding: { xs: '30px 0', sm: '30px 0', md: '40px 0' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#B51AD8",
          textAlign: 'center',
          marginBottom: "10px",
          fontSize: { xs: "1.125rem", sm: "1.125rem", md: "1.25rem" },
          maxWidth: { xs: "80%", sm: "100%",},
        }}
      >
        COMUNICANDO A NUESTRO PÚBLICO
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: {
            xs: "30px",
            sm: "2.5rem",
            md: "3rem",
            lg: "3.25rem",
          },
          width: { xs: "90%", sm: "60%", md: "60%", lg: "80%" },
          textAlign: "center",
          color: "#fff",
          fontWeight: "bold",
          marginBottom: "50px",
        }}
      >
        ENCUENTRA LOS ARTÍCULOS QUE MARCAN LA DIFERENCIA
      </Typography>
      <Grid container spacing={4} justifyContent="center" sx={{ px: { xs: 2, sm: 3, md: 4 } }}>
        {services.map((service, index) => (
          <Grid item key={index} xs={10} sm={6} md={3}>
            <Card
              sx={{
                borderRadius: '15px',
                transform: "translateY(-10px)",
                boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
                background: `
        linear-gradient(145deg, rgba(115, 17, 187, 0.1), rgba(115, 17, 187, 0)),
        linear-gradient(45deg, rgba(115, 17, 187, 0.0), rgba(115, 17, 187, 0))
      `,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <CardMedia
                component="img"
                height="300px"
                image={service.image}
                alt={service.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{
                    color: '#FFFFFF',
                    fontWeight: '500',
                    marginBottom: '10px',
                    fontSize: { xs: '16px', sm: '18px', md: '18px' },
                  }}
                >
                  {service.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: '#FFFFFF',
                    marginBottom: '20px',
                    fontSize: { xs: '14px', sm: '16px', md: '14px' },
                  }}
                >
                  {service.description}
                </Typography>
                <Button
                  component="a"
                  href="https://wa.me/51933961352"
                  target="_blank"
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: '#6F4CE0',
                    width: {xs:"70%", sm: "80%", md: "70%"},
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontSize: { xs: '14px', sm: '14px', md: '16px' },
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    "&:hover": {
                    backgroundColor: "#3E1F7E", // Cambia el color en hover
                  },
                  }}
                >
                  Saber Más
                  <ArrowOutward
                    sx={{
                      fontSize: { xs: '18px', sm: '18px', md: '22px' },
                      marginLeft: '10px',
                      transform: 'rotate(-0deg)', // Inclinación de la flecha
                    }}
                  />
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ComunityPublic;